import React from "react"
import { FaRegBell, FaHome, FaCheck, FaRegSun, FaShopify, FaUserTag, FaUserTie, FaListUl, FaBook, FaRegClipboard, FaTicketAlt, FaCogs, FaFileArchive, FaList } from 'react-icons/fa'
// import { AiFillNotification, AiFillDatabase, AiOutlineOrderedList, AiOutlineUsergroupAdd } from 'react-icons/ai'
const user = JSON.parse(sessionStorage.getItem('user'))

const navigationSuperAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "gsistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sistema1",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users"
      },
    ]
  },
  {
    id: "gdirectivas",
    title: "Directivas",
    type: "collapse",
    icon: <FaCogs size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "empresa",
        title: "Empresa",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/empresa"
      },
      {
        id: "diasnolaborales",
        title: "Días no laborales",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/diasnolaborales"
      },
      {
        id: "departamentos",
        title: "Departamentos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/departamentos"
      },
      {
        id: "indices",
        title: "Índices",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/indices"
      },
      {
        id: "mediorespuesta",
        title: "Medio Respuesta",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/mediorespuesta"
      },
      {
        id: "plantilla",
        title: "Plantilla",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/plantilla"
      },
      {
        id: "salas",
        title: "Salas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/salas"
      },
      {
        id: "serie",
        title: "Serie",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/serie"
      },
      {
        id: "tipoarchivos",
        title: "Tipo Archivos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tipoarchivo"
      },
      {
        id: "tipodocumento",
        title: "Tipo Documento",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tipodocumento"
      },
      {
        id: "TipoIdentificacion",
        title: "Tipo Identificación",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tipoidentificacion"
      },
      {
        id: "TipoSolicitante",
        title: "Tipo Solicitante",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tiposolicitante"
      },
      {
        id: "TipoTabla",
        title: "Tipo Tabla",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tipotabla"
      },
      {
        id: "TipoPQRS",
        title: "Tipo PQRS",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tipopqrs"
      },
      {
        id: "unidadconservacion",
        title: "Unidad Conservación",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/unidadconservacion"
      },
      {
        id: "versiontabla",
        title: "Versión Tabla",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/versiontabla"
      },
      {
        id: "clasedocumento",
        title: "Clase Documento",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/clasedocumento"
      },
      {
        id: "tabla",
        title: "Tabla",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/tabla"
      },
      {
        id: "pqrs",
        title: "PQRS",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/pqrs"
      },
      {
        id: "emails",
        title: "Emails",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/emails"
      },
      {
        id: "firmas",
        title: "Firmas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/directivas/firmas"
      },
    ]
  },
  {
    id: "gdocumentos",
    title: "Documentos",
    type: "item",
    icon: <FaFileArchive size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/documentos"
  },
  {
    id: "gReportes",
    title: "Reportes",
    type: "collapse",
    icon: <FaList size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "entregas",
        title: "Entregas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/entregas"
      },
      {
        id: "CorrespondenciaRadicada",
        title: "Corr. Radicada",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/correspondenciaradicada"
      },
      {
        id: "SolicitudesRealizadas",
        title: "Sol. Realizadas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/solicitudesrealizadas"
      },
      {
        id: "SolicitudesRespondidas",
        title: "Sol. Respondidas",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/solicitudesrespondidas"
      },
      {
        id: "SolicitudesPendientesRespuestas",
        title: "Sol. Pend. Respuesta",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/solicitudespendientesrespuestas"
      },
      {
        id: "DocumentosCreadosPorUsuario",
        title: "Doc. Creados",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/documentoscreadosporusuario"
      },
      {
        id: "DocumentosSinSrchivosAdjuntos",
        title: "Doc. Sin Adj.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/documentossinarchivosadjuntos"
      },
      {
        id: "EstadisticaCorrespondenciaRadicada",
        title: "Graf. Corr. Radicada.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/estadisticacorrespondenciaradicada"
      },
      {
        id: "EstadisticaDocumentosPorUsuario",
        title: "Graf. Doc. Usuarios.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/estadisticadocumentosporusuario"
      },
      {
        id: "EstadisticaDeSolicitudes",
        title: "Graf. Solicitudes.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/estadisticadesolicitudes"
      },
      {
        id: "EstadisticaDeSolicitudesTotal",
        title: "Graf. Sol. Total.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/estadisticadesolicitudestotal"
      },
      {
        id: "InventarioDocumento",
        title: "Inv. Documento.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/inventariodocumento"
      },
      {
        id: "FormatoFuid",
        title: "Formato FUID.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/formatofuid"
      },
      {
        id: "TransferenciaDocumentos",
        title: "Transf. Documentos.",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/transferenciadocumentos"
      },
      {
        id: "DocumentosCargados",
        title: "Docs. Cargados",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/documentoscargados"
      },
      {
        id: "DocumentosAdjuntos",
        title: "Docs. Adjuntos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/reportes/documentosadjuntos"
      },
    ]
  },
  /* {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  }, */
];

const navigationUser = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  }
];

const navigationShopping = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompra",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscomunicaciones",
      },
    ]
  }
];

const navigationComunicaciones = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompras",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets-comunicaciones",
      },
    ]
  }
];


const navigationConfig =
  user?.profile === 2 ? navigationSuperAdmin :
    user?.profile === 3 ? navigationUser :
      user?.profile === 65 ? navigationShopping :
        user?.profile === 75 ? navigationComunicaciones :
            []

export default navigationConfig
